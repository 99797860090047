export const claimlist = [
  "0x26bD1a2d21d1d0F796067e751bD9145Bd3BD40d3",

  "0xA0106ea530AdB815BF9663f3133b57bD45395DF6",

  "0x2C195119c0CE27Af87E6bd6bc1bA49bF91213694",

  "0x2f0B3e597518F27dFdA1019341489dc062926A9C",

  "0xC91cB8e3079BEF7F92E8de6caa2d011E95bDAa1a",

  "0x6fFbcA4259dFceC4B72BEd5008704468208e7635",

  "0x2011603dFa8cE50080A5Dbfda8495f982c5d8290",

  "0x1a962b7f2Ce5Df65Ae4Ed0A0e9BE900C8a79487c",

  "0x6965938D5F04Ca3B4E0DCb3AE48B0F54B9478595",

  "0x1eAcaC17AA2a56dC371919b26548Efc661892F34",

  "0xacc4406749f0c0f673cfc3ca0d1ed93af8a5ab5d",

  "0xc9bd8d37302bfa4cddb8afad3a03cd187f3f2318",

  "0x28c95505323ad74f6da3ea34bda3a593e34f9d91",

  "0x3a00b40410a9843a8094cf52799cbf192e5a1994",

  "0x833c6b7b0b05aba75f1ddb5211b20203b6a7c149",

  "0x61186906875e8509B7FcF519a69a648C341e2600",

  "0xF96b453acd15b5822A5C3a4ef4211CA58027C672",

  "0x01c4dae2500a67adb3176e5afad34cbe2558d587",

  "0x01cedb87fd84c4c8c64e87eb498316b72ec880fd",

  "0x02033d6234879d0785f078db4586277d553e4314",

  "0x07dfdf8c15cd738d0d85830127646fb6b2e4cadd",

  "0x09355daa449ad47d5bc03f24ca88ced5f8537d5f",

  "0x09c6232d54b51841a202d76f76fa679df53a3b3e",

  "0x0aba38de15c2e82ba5313188a63d375707edd758",

  "0x0d3a3644f100c011d9e193ba57f96a64332f63f2",

  "0x0d61c98233b30543909c79acd1db289d9866dcfd",

  "0x18c234ffe18defa76ed52eae4dab3bd5f4f1b91f",

  "0x1a2cf16941505fed6a60ebbb339096cf4cac8b92",

  "0x1bbcb371e0f03b5cf52ee488dab42c9999f8f9ec",

  "0x1c69bbc0036369bdde05a45db5d8415480dc3d48",

  "0x21fdf0f40bce40f83fa9d9f514aaf2493e624cbc",

  "0x25f54b82620f433d967ca56ed4939de0c6487c2d",

  "0x2659c916a3443d6ecca407fee95b4f457b43dd25",

  "0x2d676a8b16144fb655bcde0f6c47a455924530b5",

  "0x2e47da78add91cf596218911c38df128ddca7327",

  "0x2ee34dfbd35f087c734cb6975bb3fc7374cc6ae6",

  "0x2f5fcac9f7c42885812d29997b0f9e0aa53a220f",

  "0x34edee05622e6d70d7224c14654f3b889d6ea90f",

  "0x3648d5a0ac4b82ac094e6bf8755c4b126c0c1d9c",

  "0x366da73bc1c714240c5206802d7835af64d5b4e5",

  "0x3aab0f444809bd1d69a55a62ff262dcaffbe0246",

  "0x3ba5b492e2aac328f10d753350ac8ecffbe2294a",

  "0x48f2771589ad39ab1090958d7fafef23f821498a",

  "0x4c648b9029dcce6e596e13d583c9a389555a45a8",

  "0x50729246d44ad421ac22adfc339e0f2b79239de1",

  "0x512f073f5c418085d2f296e0c5bdf30eaa5ade10",

  "0x5973bf00521377cfa4e22dc51e8f7f733e8ef7a6",

  "0x5b918aeb0e8e80f2aa08eb73d9323c3c52737649",

  "0x5bf2e8a9456b72d5603893e0b57b92ce344652f5",

  "0x5d2c17a42186cef90feb1f37ff49289a65f02768",

  "0x5fa87e6416a218174dc1a427717295c2fee5a494",

  "0x613a67c33be7ac9cea3d8385b74160d0c4b313cd",

  "0x617506ced935c4265dfa9e78f771d493c7ea0624",

  "0x61c8327824f125753a370a8d730fc6f67e00eb75",

  "0x67eab88b04cf3aac352e199071828e7ef2e8d3e1",

  "0x6904b81ead30d7468463be55d95cd06712e9fd59",

  "0x6915fca329c16f5980f032a95669ff19060599c3",

  "0x6e60f1940e274dde12ff18a65d224798ee76e38f",

  "0x6eb3e44d5775b36bf778303e60b61864cb60889e",

  "0x6ef967e28765e5bfdc8bb002965042ca81236f82",

  "0x7001d574340bcfeebc8a238a76145deafa1f1cf0",

  "0x70242326b8067a945aa6f739ab94d9696be6eb1b",

  "0x7299d4e578907d5751cbf60763d258823bfbb18b",

  "0x73fa4003048c06cb2c2ad06f64615a7405c123aa",

  "0x76ef2462d321c054a9ed3d2add9be79d97689514",

  "0x7b3c4f16639ee908e895cf3855afc14e1a95c10e",

  "0x7c1af8beee49fd659530b08afee360fd8b3fc930",

  "0x7f4ae46807bbf472f9f5cda828c4681f0687a36c",

  "0x81b70a3ddb95bde22147d9cb51040ec209d2784f",

  "0x873f0ece12a8ff26b092e2b69f2936d024d28636",

  "0x8beae4a9043dc7c63503d40701d61e10b3b8c094",

  "0x8df26304ead15912ed50da7b627a9073dd5719e7",

  "0x8e35db408dbab9cea50f5230c3f1616234f6aa8a",

  "0x90575a22267a27f93a3135f32291a5880eb29185",

  "0x906a0174490e9459bb7bf9547b5db54d208f8f7e",

  "0x9224e43fb7766d6b51a3c900cc77720cba75e91c",

  "0x93131be7daf7ee0f499c883e6297a8fd648871e7",

  "0xa06689c0c56cc96a58374e5cacaf2f651db9f537",

  "0xa369c43126cee3934f4cc6f8e698889fb6ff1aa0",

  "0xa37dd7c67c5c985aa56f7cf362b29d83c3f36816",

  "0xa537be863cc90d5095f09747c4c42643286ba592",

  "0xaab5300471f1ff91b9dcbb8d09966667381c0759",

  "0xac368900583ef2a7eab743c890de6b47bf55b9c3",

  "0xade8aff0ed3b9a1efb9456374e80ca9d97a2cc5b",

  "0xafb6fe9d03a94b0caeb533b8dbaec4ec41a835e5",

  "0xb4becd97f4590ff0c743f4b61fc3449ebbdb646a",

  "0xb5b4141ad459b49b2a24e77a602f83e9ee75b189",

  "0xb5bdcce0950760df511084737e6c9f49d416944c",

  "0xb6912d7722f327aa0657c17f85de5913fe19e38c",

  "0xbb36cb8fcf491824be74284283637a0cedd96b46",

  "0xbe0fba7b51a981406ae6c4a8b7040a6f656f91e6",

  "0xbf265ca7c7ace01469959aad580171ad6d43d8f3",

  "0xbf4e112f41288206501c2fbf48421f9c4ed06ddf",

  "0xc564bb05427171246fc87385004762ad68770942",

  "0xc72e316d149367babe1234697e390ed43f77d23e",

  "0xc9b9e1c2aecfdaf09c92a5033efa5ce67df225b2",

  "0xcccbc2ec015cabb916b1fbf9dbf3930efc758dda",

  "0xd071ed6c3f9d51ab59bc1129fa51f075c9904c83",

  "0xd0ddd0cc9124f188eea4e7457a30db77f138b47d",

  "0xd18332ce9d6fc441f69e6afc4b33410b6d21b7a7",

  "0xd2e5a0d288ba2609625578e254450a0c05ac9a43",

  "0xd613812c73173c27d7ef3d6bd55657f4351f1709",

  "0xd7325657af1678a25e959cfbdc3fde164cca6346",

  "0xd81e951eea4736afed5503f2ba44b710915f5bb2",

  "0xde6a4341202887ccc2c540c1bc596f7aeb8a731a",

  "0xe0c34da87c3c167c8df38e07dff5ddc34852106c",

  "0xe5060e352bf8a90a9e60f2468def5d3d6693df5d",

  "0xe9c6b3510485c6dd9122fcd766136e24a7b45ef7",

  "0xee4df2df2103b692b01177edba8b56ab3cc8ea39",

  "0xeff4e1e6b78ab613a58d8c4b205f196b5d6bb2aa",

  "0xf0553ed10f41ed17172cbd285a418f894f477dc6",

  "0xf150864e56e0d4d3b78a3304aa9958da87260078",

  "0xf1cbce77f277d27c6166ae07be60070ef80a5b0c",

  "0xf229c4d823b3b523239a1f5d999e3fb9793c324a",

  "0xf3d7459c7f6f75252aadf594d2ea74f04b359f82",

  "0xf69eb404e5fa4f95c9f739cc24c6a1b963124e3a",

  "0xfd93d4e020a8c778524ef82bfa81801dbbe62c92",

  "0xfdb7f8a52deddf1fce93e83f3e6dc58f8dcac784",

  "0x8a5f4ce0bdbaf0e7affb0a3d280f8e273ec92bc3",

  "0x5d8448291927b46a2e3cac16066c17e8b2673c9c",

  "0xf4588b7a65916d0b700b793d1dfd18cc7401d17d",

  "0xaf309f5a573889967d359710d4a2c08b30cb3e9c",

  "0x47cf0b0f692d69e817e06b9beadba96fe763edae",

  "0x80981E56E4b23E5C29918A5cab64fA32251BD390",

  "0xbB09B41bBc9f072bE91B906A96CDc358645f5A7A",

  "0x32267d604f90AcAf3469d7F34e5458093911fa9f",

  "0x14C01f99bb32cDC9712642368f8E3a9BF00aC6fb",

  "0xD644C1B56c3F8FAA7beB446C93dA2F190bFaeD9B",

  "0x1F9E539D76eCc21830fa6a7CdeA4cf3946927A04",

  "0x62D5Ea0e1a6090Df334d9a594224603E45B0dF71",

  "0x5acCB5DBBd0f4A4F84552C156e0c9b3146826A01",

  "0x03880ed496be86ab8ce8e3223805a20c765245be",

  "0x18f25ecf2d37dab8c7d480cbaa1450e89eaedb9e",

  "0x250218c60c5b2b387cd2b5725fd16a57cd9099d0",

  "0x28c95505323ad74f6da3ea34bda3a593e34f9d91",

  "0x30b34abd2bb9302dfbd9872a4819ece1a2dac583",

  "0x31e2b21468e1fbe3eebae37907f8c452112a286d",

  "0x32ee629f860dce1589be9beb2cb21b745523bb07",

  "0x366b5b8da59de816e0ff8cddc3f4cae006df08da",

  "0x3afeb9b7dbe84a70c88aca93048c538bc56214d5",

  "0x3d5ec9de3dce1dc7f233257bd689a9bc09b98a5c",

  "0x40980c46d43e7ec3ad8b49db2044f1a0724c48eb",

  "0x4b9e2a507cc8c21420e43db9ca368c54c90d6329",

  "0x4bdc70003349e26591e4631c35969e7b6751d58d",

  "0x4e2687f6edae16de9759348f83b4b766982a15a0",

  "0x4e6e885f943626f1f580cc4a7e8cb7072a45881c",

  "0x569953a637c56599bef622ab70e2dc67379a4df7",

  "0x5708df8c01d2bcec22dfa66c501e27c197ba2470",

  "0x5b80e745edac25909ec2634aafa72993f918c1a8",

  "0x5f9a7cf1f980ded04b030506252a4330b170c949",

  "0x5fa8f16453c33465acc79a618e9dbfbf0dc017b0",

  "0x6021a327f68cd58ebf4eb0f01e7f93b96443aa3d",

  "0x6066b41a17b01c578327da8371270484fcb87bdf",

  "0x625a3ecb47b991f398b02d43c0da335f471c66f1",

  "0x6455ce58efb402797476a54f5ba6870bff6b9b48",

  "0x64f0d8f2804990ff5a8f085b111384b328b9230d",

  "0x681d644155eb6c90e29f017575255c12cffa0a2b",

  "0x8a8b55bf8621d4802d0a3892a4cbb16928ea0bc3",

  "0x96577ea245c828153e40e5ac9098c98379ba2b57",

  "0x967110b7bbb7c492124663a96eb75ce888a1002c",

  "0x9837abcfc869111c050667a0c2d7bef126b36751",

  "0xa2f2db0202cf94de28822019598140b335b2f0d7",

  "0xa83abe5d63e6d575fdf38188b01f4be570b04a9f",

  "0xaed142a76f74d500061cc7f0d8aa87078b43622a",

  "0xc2c5078f84822b0e993ce67b439f6886fa4f2bfc",

  "0xcaddadbe9b616b7b7a5e3b5835723a71479b5751",

  "0xd0d91080381a5c9f931b258e35558953afa2f88c",

  "0xda7ec957ff2d0081baf7bbb82ebb7d193b5f6971",

  "0xe036e9ad7702c1389a22cc5d8ff88f63137e6566",

  "0xe8173a050214086bbaafa443feaf712f9bcc1b0d",

  "0xe983f42cf38ddd6854fcb3ab5d139113aa64aaf5",

  "0xf0163a5eb4077a58ae9ce46b13fea0296d374131",

  "0xf4339cf398568df28863a595226a7f998815e6fb",

  "0xf518f5e742f3002974c1f8ccf0f33b18e1d60fb4",

  "0xfa3c8aa69c842bf9f2bc093a0d9a6a4cb0ce123d",

  "0x8cd0fad27cf780de2b1813859b4f8170e6323dea",

  "0xcc91a8b326d943be020a35b35e0bd68d61a80246",

  "0x5a0878e69b8b4eaedfd3d768e3aaf50c4fe59c02",

  "0xa5537e2c16982c422df83f842e6af13a2f439037",
  "0x51954725c3c90a97bBa4DDC466c0d61b5b8c0E39",
  "0x9AC0eB3f9293b89A2854FA9c5c9d4747f9ed6AAe",
  "0x138e12e81cc64a667752cD70b4C6E1681bF91378"
];
